import axios from "axios";
const baseURL = process.env.REACT_APP_BACKEND_MAIN_BASE_URL;

const token = localStorage.getItem("token");

export const searchAndContents = async (
  query,
  startPublishedDate,
  numResults
) => {
  const response = await axios.post(`${baseURL}exa`, {
    query,
    startPublishedDate,
    numResults,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
