import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { BiCopy } from "react-icons/bi";
import { FaLightbulb } from "react-icons/fa";
import {
  IoArrowRedoSharp,
  IoCheckmarkCircleOutline,
  IoCopyOutline,
} from "react-icons/io5";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { lucario } from "react-syntax-highlighter/dist/cjs/styles/prism";
import copy from "copy-to-clipboard";
import { marked } from "marked";

// import * as complianceActions from '../store/actions/compliance';

import ChatInput from "./ChatInput/ChatInput.tsx";
import QueriesAndResultsModal from "./QueriesAndResultsModal";

import {
  breakResponseIntoChunks,
  formatChatThread,
  formatSources,
  formatWebSources,
  highlightNonCompliantWords,
  sendFactCheckerMessageDev
} from "../logic/chat.tsx";
import {
  createChatThread,
  getChatThreadInteractions,
  getChatThreadResponse,
  pollChatResponse,
} from "../services/chat.tsx";

import AdvisorXLogo from "../assets/advisorx-logo.png";
import { setMessageForReview } from "../features/compliance/complianceSlice.ts";
import { useAppSelector } from "../hooks.ts";
import { RootState } from "../store.js";
import { welcomeSuggestions } from "./BrowsePrompts/data.js";
import BouncingDots from "./Loaders/BouncingDots/BouncingDots.js";
import "./SuperpoweredChatThread.css";
import { useToast } from "../context/ToastContext.js";
import { Tooltip } from "@mui/material";
import { chat_and_long_form_kbs } from "../utils/knowledge_bases.ts";
import { WebSearchConfig } from "./WorkflowConfigModal.js";
import { updatedIncludeDomains } from "../utils/included_domains.ts";
import {
  presetSystemMessages,
  webSearchPresets,
} from "../utils/postGeneratorPresets.js";
import { setMessageForChart } from "../features/chart/chartSlice.ts";

interface SuperpoweredChatThread {
  apiKey: string;
  apiSecret: string;
  isDarkMode: boolean;
  threadId: string | null;
  chatConfig: ChatConfig;
  initialMessage: string;
  onPersonalizationClick: () => void;
  onPersonaClick: () => void;
  showPeronaSearch: boolean;
  onHumanizeClick: () => void;
  personaTitle: string;
  onFactCheckerClick: () => void;
}

interface ChatConfig {
  model: string;
  knowledgeBaseIds: string[];
  systemMessage: string;
  autoQueryGuidance: string;
  temperature: number;
  responseLength: string;
  useWebSearch: boolean;
  webSearchConfig: object;
  includeDomains: string[];
}

interface ChatMessageObject {
  id: string;
}

const SuperpoweredChatThread: React.FC<SuperpoweredChatThread> = ({
  isDarkMode,
  threadId,
  chatConfig,
  initialMessage,
  onPersonalizationClick,
  onPersonaClick,
  showPeronaSearch,
  onHumanizeClick,
  personaTitle,
}) => {
  const userObj = useSelector((state: RootState) => state?.auth?.user);
  const userName = userObj?.first_name || "" + " " + userObj?.last_name || "";

  const firstName = userName ? userName.split(" ")[0] : "";

  const theme = isDarkMode ? "dark" : "light";

  const chatContainerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showThinkingDots, setShowThinkingDots] = useState(false);
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);
  const [suggestionMessage, setSuggestionMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [topMessageId, setTopMessageId] = useState<string | null>(null);
  const [chatThreadId, setChatThreadId] = useState(threadId);
  const [nextPageToken, setNextPageToken] = useState("");

  const [queries, setQueries] = useState([]);
  const [results, setResults] = useState([]);
  const [citedResults, setCitedResults] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [showQueriesAndResultsModal, setShowQueriesAndResultsModal] =
    useState(false);
  const authToken = useAppSelector((state) => state.auth.idToken);

  const user = useAppSelector((state) => state.auth.user);

  type MessageRefs = Record<string, React.RefObject<HTMLElement>>;
  const messageRefs: MessageRefs = chatMessages.reduce(
    (acc: MessageRefs, message: ChatMessageObject) => {
      acc[message.id] = React.createRef();
      return acc;
    },
    {} as MessageRefs
  ); // Cast the initial value to MessageRefs type

  async function requestChatInteractions(newThreadId: string) {
    setIsLoading(true);
    const [interactions, status] = await getChatThreadInteractions(
      authToken,
      newThreadId
    );
    if (status === 200) {
      // Update the next page token
      setNextPageToken(interactions.next_page_token);

      // Set the chat messages to the current thread's messages
      let formattedChatMessages = formatChatThread(interactions);
      /*let initialMessages = {};

      if (initialMessage !== null && initialMessage !== "") {
        // Set the initial message
        initialMessages = {
          prefix: "ai",
          content: initialMessage,
          sources: [],
          id: `ai_0000000000`,
        };
      }
      formattedChatMessages.unshift(initialMessages);*/
      // Append the new message to the beginning of the current messages
      setChatMessages(formattedChatMessages);
      setIsLoading(false);
    } else {
      alert("Error getting chat interactions");
      setIsLoading(false);
    }
  }

  async function requestMoreChats() {
    setIsLoading(true);
    setDisableAutoScroll(true);
    // Request the next page of chat interactions
    const [interactions, status] = await getChatThreadInteractions(
      authToken,
      chatThreadId,
      nextPageToken
    );
    if (status === 200) {
      // Format the chat messages
      const newChatMessages = formatChatThread(interactions);

      // Update the chat messages. The new ones need to be added to the beginning of the array
      setChatMessages(newChatMessages.concat(chatMessages));

      let newNextPageToken = interactions.next_page_token;
      setNextPageToken(newNextPageToken);
      setDisableAutoScroll(true);
      setIsLoading(false);
    } else {
      setDisableAutoScroll(true);
      setIsLoading(false);
      alert("Error getting chat history");
    }
  }

  async function createNewChatThread() {
    setIsLoading(true);
    const knowledgeBaseIds =
      chatConfig.knowledgeBaseIds == undefined
        ? []
        : chatConfig.knowledgeBaseIds;
    const [resData, status] = await createChatThread(
      authToken,
      knowledgeBaseIds
    );
    if (status === 200) {
      setChatThreadId(resData.id);
    } else {
      //TODO: Handle error
      // This probably means the api key and/or secret aren't valid
    }
    setIsLoading(false);
  }

  async function sendFactCheckerMessage(message: string) {

    setShowThinkingDots(true);
    const userInput = "Fact check the previous message.";

    // Update the redux store
    let maxID = "0000000000";
    if (chatMessages !== undefined && chatMessages.length > 0) {
      maxID = chatMessages[chatMessages.length - 1]["id"];
    }
    const newID = (parseInt(maxID) + 1).toString().padStart(10, "0");
    let newMessages = [
      ...(chatMessages || []),
      { prefix: "user", content: userInput, sources: [], id: `user_${newID}` },
    ];

    setChatMessages(newMessages);

    const finalMessage = await sendFactCheckerMessageDev(message)
    console.log("finalMessage", finalMessage)
    sendMessage(userInput, finalMessage, [], "gpt-4", false, [], true);
  }

  async function reviewForCompliance(message: string) {

    const userInput = "Review for compliance";
    let systemMessage = presetSystemMessages.sec;
    systemMessage += "Your task is now to review the following message for compliance: \n\n" + message;
    const complianceKbID = "9d906af9-1384-4e74-8879-1625bab3daeb";
    console.log("message", message)
    console.log('systemMessage', systemMessage)
    sendMessage(userInput, systemMessage, [], "claude-3-sonnet", false, [complianceKbID]);

  }

  async function sendMessage(
    message: string,
    systemMessage: string = "",
    webSearchPresets: Array<string> = [],
    modelOverride: string = "",
    useWebSearchOverride: boolean | null = null,
    knowledgeBasesOverride: Array<string> = [],
    omitSetUserMessage: boolean = false
  ) {
    // const model = chatConfig.model == undefined ? "claude-3-opus" : chatConfig.model;
    const temperature =
      chatConfig.temperature == undefined ? 0.2 : chatConfig.temperature;

    if (systemMessage === "") {
      systemMessage = chatConfig.systemMessage;
    }

    let knowledgeBaseIds: any = chatConfig.knowledgeBaseIds || [];
    knowledgeBaseIds = chatConfig.knowledgeBaseIds || [];
    if (knowledgeBasesOverride.length > 0) {
      knowledgeBaseIds = knowledgeBasesOverride;
    }

    const useRSE = true;
    const targetSegmentLength =
      chatConfig.targetSegmentLength == undefined
        ? "medium"
        : chatConfig.targetSegmentLength;
    const responseLength =
      chatConfig.responseLength == undefined
        ? "medium"
        : chatConfig.responseLength;
    const autoQueryGuidance =
      chatConfig.autoQueryGuidance === undefined
        ? ""
        : chatConfig.autoQueryGuidance;

    setShowThinkingDots(true);

    // Update the chat messages in the state variable (only if the status is 200)
    //if (!omitSetUserMessage) {

    // Update the redux store
    let maxID = "0000000000";
    if (chatMessages !== undefined && chatMessages.length > 0) {
      maxID = chatMessages[chatMessages.length - 1]["id"];
    }
    const newID = (parseInt(maxID) + 1).toString().padStart(10, "0");

    let newMessages = [
      ...(chatMessages || []),
      { prefix: "user", content: message, sources: [], id: `user_${newID}` },
    ];

    setChatMessages(newMessages);
    //}

    let webSearchConfig = chatConfig.webSearchConfig;
    /*if (webSearchPresets.length > 0) {
      webSearchConfig["include_domains"] = webSearchPresets;
    } else {
      webSearchConfig["include_domains"] = ["finance.yahoo.com", "forbes.com"] // updatedIncludeDomains;
    }*/

    let model = chatConfig.model || "gpt-4";
    if (modelOverride !== "") {
      model = modelOverride;
    }

    const useWebSearch = useWebSearchOverride === null ? chatConfig.useWebSearch : useWebSearchOverride;

    /*console.log(
      authToken,
      chatThreadId,
      message,
      knowledgeBaseIds,
      model,
      temperature,
      systemMessage,
      useRSE,
      targetSegmentLength,
      responseLength,
      useWebSearch,
      webSearchConfig,
      "GET CHAT THREAD RESPONSE"
    );*/
    console.log("knowledgeBaseIds", knowledgeBaseIds);

    const [resData, status] = await getChatThreadResponse(
      authToken,
      chatThreadId,
      message,
      knowledgeBaseIds,
      model,
      temperature,
      systemMessage,
      autoQueryGuidance,
      useRSE,
      targetSegmentLength,
      responseLength,
      useWebSearch,
      webSearchConfig
    );

    console.log("resData", resData);
    console.log("status", status);

    if (status === 202) {
      let responseStatus = resData["status"];
      const pollURL = resData["id"];

      let pollCount = 0;
      let modelResponseText = "";
      while (responseStatus !== "COMPLETE" && pollCount < 200) {
        let [resData, status] = await pollChatResponse(authToken, pollURL);
        responseStatus = resData["status"];

        // Check for a status of FAILED
        if (responseStatus === "FAILED") {
          break;
        }
        pollCount += 1;
        console.log("pollCount", pollCount);

        //let newMessages = [...chatMessages || []];
       // console.log("newMessages", newMessages);
        let newChatMessagesWithAiResponse = [...newMessages];
        // Update the message if the status is IN_PROGRESS, and there is a model response
        if (
          resData["response"]["interaction"]["model_response"] !== null &&
          resData["response"]["interaction"]["model_response"]["content"] !==
          null
        ) {
          const modelResponse =
            resData["response"]["interaction"]["model_response"]["content"];

          let chunks = breakResponseIntoChunks(
            modelResponseText,
            modelResponse
          );
          if (chunks.length > 0) {
            const sleepTime =
              (user?.chat_config?.model &&
                user?.chat_config?.model === "claude-3-opus"
                ? 500
                : 250) / chunks.length;

            setShowThinkingDots(false);

            for (let i = 0; i < chunks.length; i++) {
              // update the chat thread with the AI response in the state variable
              const newAiResponse = {
                prefix: "ai",
                content: modelResponseText + chunks[i],
                sources: [], // Don't show the sources yet
                searchResults: [],
                searchQueries: [],
              };
              // newChatMessages won't get updated in this loop. It is a constant, not a state variable
              const newChatMessagesWithAiResponse = [
                ...newMessages,
                newAiResponse,
              ];
              setChatMessages(newChatMessagesWithAiResponse);
              modelResponseText += chunks[i];

              await new Promise((r) => setTimeout(r, sleepTime));
            }
          }
        }

        if (responseStatus === "COMPLETE") {
          // Update the sources
          const sources = formatSources(
            resData["response"]["interaction"]["references"],
            resData["response"]["interaction"]["ranked_results"]
          );

          let webSources = [];
          if (
            resData["response"]["interaction"]["web_search_references"] !==
            undefined &&
            resData["response"]["interaction"]["web_search_references"] !== null
          ) {
            webSources = formatWebSources(
              resData["response"]["interaction"]["web_search_references"],
              resData["response"]["interaction"]["web_search_results"]
            );
          }

          // Extract the metadata from the ranked_results
          let searchResults = [];
          for (
            let j = 0;
            j < resData["response"]["ranked_results"].length;
            j++
          ) {
            searchResults.push(
              resData["response"]["ranked_results"][j]["metadata"]
            );
          }

          const newAiResponse = {
            prefix: "ai",
            content:
              resData["response"]["interaction"]["model_response"]["content"],
            sources: sources,
            webSources: webSources,
            searchResults: searchResults,
            searchQueries: resData["response"]["interaction"]["search_queries"],
            webSearchResults:
              resData["response"]["interaction"]["web_ranked_results"],
            webSearchQueries:
              resData["response"]["interaction"]["web_search_queries"],
            id: `ai_${resData["response"]["interaction"]["id"]}`,
          };
          newChatMessagesWithAiResponse = [...newMessages, newAiResponse];
          setChatMessages(newChatMessagesWithAiResponse);
          break;
        }
        // Sleep for 0.25 seconds
        if (
          resData["response"]["interaction"]["model_response"] == null ||
          resData["response"]["interaction"]["model_response"]["content"] ==
          null
        ) {
          await new Promise((r) => setTimeout(r, 250));
        }
      }
    } else {
      //TODO: Handle error
      setShowThinkingDots(false);
    }
  }

  function showQueriesAndResults(queries, results, sources, source) {
    setQueries(queries);
    setResults(results);
    setCitedResults(sources);
    setSelectedSource(source);
    setShowQueriesAndResultsModal(true);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersectingEntries = entries.filter(
          (entry) => entry.isIntersecting
        );

        if (intersectingEntries.length > 0) {
          const topEntry = intersectingEntries.reduce((top, entry) =>
            entry.boundingClientRect.top < top.boundingClientRect.top
              ? entry
              : top
          );
          setTopMessageId(topEntry.target.id);
        }
      },
      {
        root: chatContainerRef.current,
        threshold: 1.0,
      }
    );

    Object.values(messageRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(messageRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [chatMessages]);

  useEffect(() => {
    setChatThreadId(threadId);
    if (threadId === null) {
      createNewChatThread();
    } else {
      // When the chat thread changes, request the interactions for this thread
      requestChatInteractions(threadId);
    }
  }, [threadId]);

  useEffect(() => {
    //console.log(chatContainerRef.current && !disableAutoScroll)
    if (chatContainerRef.current && !disableAutoScroll) {
      const { scrollHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight + 100;
    } else if (chatContainerRef.current && disableAutoScroll) {
      // This is the case when someone clicks View previous chats
      // We only want to scroll to the top of the previous chats
      chatContainerRef.current.scrollTop =
        messageRefs[topMessageId].current.offsetTop - 100;
    }
  }, [chatMessages, showThinkingDots, chatContainerRef]);

  return (
    <div className={`chat-main-section-${theme}`}>
      {isLoading && <BouncingDots />}
      <div className={`chat-main-container`}>
        <div className="chat-messages-container" ref={chatContainerRef}>
          {nextPageToken !== null && nextPageToken !== "" && (
            <div className="view-previous-chats-container">
              <p
                className={`view-previous-chats-text-${theme}`}
                onClick={() => requestMoreChats()}
              >
                View previous chats
              </p>
            </div>
          )}

          {showQueriesAndResultsModal && (
            <QueriesAndResultsModal
              onClose={() => setShowQueriesAndResultsModal(false)}
              queries={queries}
              citedResults={citedResults}
              allResults={results}
              theme={theme}
              selectedSource={selectedSource}
            />
          )}

          {chatMessages?.length > 0 ? (
            chatMessages?.map((chatMessage, index) => (
              <div
                id={chatMessage["id"]}
                key={index}
                ref={messageRefs[chatMessage.id]}
              >
                <ChatBox
                  theme={theme}
                  aiOrUserMessage={chatMessage["prefix"]}
                  message={chatMessage["content"]}
                  sources={chatMessage["sources"]}
                  searchQueries={chatMessage["searchQueries"]}
                  onSourceClick={(
                    searchQueries,
                    searchResults,
                    sources,
                    source
                  ) =>
                    showQueriesAndResults(
                      searchQueries,
                      searchResults,
                      sources,
                      source
                    )
                  }
                  showQueriesAndResultsModal={(sources, source) =>
                    showQueriesAndResults(
                      chatMessage["searchQueries"],
                      chatMessage["searchResults"],
                      sources,
                      source
                    )
                  }
                  chatMessage={chatMessage}
                  firstName={userName}
                  webSources={chatMessage["webSources"]}
                  firstName={userName}
                  onHumanizeClick={() =>
                    onHumanizeClick(chatMessage["content"])
                  }
                  onFactCheckerClick={() =>
                    sendFactCheckerMessage(chatMessage["content"])
                  }
                  onComplianceClick={() =>
                    reviewForCompliance(chatMessage["content"])
                  }
                />
              </div>
            ))
          ) : (
            <div className="welcome-text-wrapper">
              <h1>Hello, {firstName}.</h1>
              <h2>How can I help you today?</h2>
              {/* <div className="suggestion-wrapper">
                {welcomeSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-chip"
                    onClick={() => setSuggestionMessage(suggestion.text)}
                  >
                    <p>{suggestion.text}</p>
                    <FaLightbulb className="suggestion-icon" />
                  </div>
                ))}
              </div> */}
            </div>
          )}

          {showThinkingDots && (
            <div
              className={`chat-message-container-${theme} ai-chat-message-container-${theme}`}
            >
              <ThinkingChatMessage isVisible={showThinkingDots} />
            </div>
          )}
        </div>

        <div className={`chat-send-message-container`}>
          <ChatInput
            suggestionMessage={suggestionMessage}
            sendMessage={(message) => sendMessage(message)}
            placeholderText={"Type your message here..."}
            theme={theme}
            onPersonalizationClick={() => onPersonalizationClick()}
            onPersonaClick={() => onPersonaClick()}
            showPeronaSearch={showPeronaSearch}
            personaTitle={personaTitle}
          />
        </div>
      </div>
    </div>
  );
};

//showQueriesAndResults(chatMessage["searchQueries"], chatMessage["searchResults"], sources, source)

interface ThinkingChatMessageProps {
  isVisible: boolean;
}

export const ThinkingChatMessage: React.FC<ThinkingChatMessageProps> = ({
  isVisible,
}) => {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    let dots = 0;
    const intervalId = setInterval(() => {
      dots = (dots + 1) % 4;
      const newMessage = `${".".repeat(dots)}`;
      setMessage(newMessage);
    }, 250);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (!isVisible) {
    return <div></div>;
  } else {
    return (
      <div className="superpowered-thinking-chat-message">
        <p className="superpowered-thinking-dots">{message}</p>
      </div>
    );
  }
};

const ChatBox = ({
  aiOrUserMessage,
  message,
  onSourceClick,
  sources,
  theme,
  webSources,
  chatMessage,
  firstName,
  onHumanizeClick,
  onFactCheckerClick,
  onComplianceClick,
}) => {
  // aiOrUserMessage is either "ai" or "user"
  const { toggleToast } = useToast();
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part[0])
      .join("")
      .slice(0, 2);
    return initials.toUpperCase();
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [showCopiedText, setShowCopiedText] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [copyText, setCopyText] = useState("");

  /*function sendToCompliance() {
    dispatch(setMessageForReview(message));
    history.push("/compliance");
  }*/

  function sendToChart() {
    dispatch(setMessageForChart(message));
    history.push("/charts");
  }

  const formattedMessage = highlightNonCompliantWords(message);
  //console.log("formattedMessage", formattedMessage)

  function copyMessageText() {
    // Copy the text to clipboard
    // Create a temporary element to hold the HTML content
    const htmlContent = marked(message);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Extract and simplify the text to retain basic formatting
    let plainText = tempDiv.textContent || tempDiv.innerText || "";

    // Replace markdown's **bold** with plain text bold etc. if needed
    // Example: plainText = plainText.replace(/\*\*(.*?)\*\*/g, 'YOUR_LOGIC_HERE');

    // Use copy-to-clipboard to copy the processed text
    copy(plainText);

    // Show the copied text for 3 seconds
    toggleToast("Copied to clipboard", false);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  }

  function getMarkdownText(children) {
    navigator.clipboard.writeText(children[0]);
    setCopyText(children[0]);

    // Show the copied text for 2 seconds
    setShowCopiedText(true);
    setTimeout(() => {
      setShowCopiedText(false);
    }, 3000);
  }
  return (
    <div
      className={`chat-message-container-${theme} ${aiOrUserMessage}-chat-message-container-${theme}`}
    >
      <div className="chat-message-header-container">
        {/* <p className="chat-message-header-text">
        {aiOrUserMessage === "ai" ? "AI" : "USER"}
      </p> */}

        {aiOrUserMessage === "ai" ? (
          <img className="ai-chat" src={AdvisorXLogo} alt="" />
        ) : (
          <div className="user-chat">{getInitials(firstName)}</div>
        )}

        <div className="secondary-buttons-wrapper">
          <div className="copy-wrapper" onClick={() => onHumanizeClick()}>
            {aiOrUserMessage === "ai" && (
              <div className="humanize-wrapper">
                <p className="humanize-text">Humanize</p>
              </div>
            )}
          </div>

          <div className="copy-wrapper" onClick={() => copyMessageText()}>
            {aiOrUserMessage === "ai" && (
              <IconContext.Provider
                value={{ className: `copy-text-icon-${theme}` }}
              >
                <div>
                  {showCopiedMessage ? (
                    <IoCheckmarkCircleOutline />
                  ) : (
                    <Tooltip arrow title="Copy Text" placement="top">
                      <div>
                        <IoCopyOutline />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </IconContext.Provider>
            )}
          </div>
          {/*<div onClick={() => sendToCompliance()}>
            <p className={`send-to-review-text`}>send to review</p>
            {aiOrUserMessage === "ai" && (
              <IconContext.Provider
                value={{ className: `copy-text-icon-${theme}` }}
              >
                <Tooltip arrow title="Compliance Review" placement="top">
                  <div>
                    <IoArrowRedoSharp />
                  </div>
                </Tooltip>
              </IconContext.Provider>
            )}
          </div>*/}
        </div>
      </div>
      <div className="chat-message-content-container">
        <ReactMarkdown
          children={formattedMessage}
          className={`chat-message-text-${theme}`}
          rehypePlugins={[rehypeRaw]}
        />

        {sources.length > 0 && (
          <div className="sources-container">
            <div className="sources-col">
              <p
                className={`semi-bold-font-${theme}`}
                style={{ fontSize: "16px" }}
              >
                Persona Sources
              </p>
            </div>
            <div className="sources-col">
              {sources.map((source, index) => (
                <div
                  key={index}
                  className="source-row"
                  onClick={() =>
                    onSourceClick(
                      chatMessage["searchQueries"],
                      chatMessage["searchResults"],
                      sources,
                      source
                    )
                  }
                >
                  <p className={`source-text-${theme}`}>{source["title"]}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {webSources !== undefined && webSources.length > 0 && (
          <div className="sources-container">
            <div className="sources-col">
              <p
                className={`semi-bold-font-${theme}`}
                style={{ fontSize: "16px" }}
              >
                Web Sources
              </p>
            </div>
            <div className="sources-col">
              {webSources.map((source, index) => (
                <div
                  key={index}
                  className="source-row"
                  onClick={() =>
                    onSourceClick(
                      chatMessage["webSearchQueries"],
                      chatMessage["webSearchResults"],
                      webSources,
                      source
                    )
                  }
                >
                  <p className={`source-text-${theme}`}>{source["title"]}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {aiOrUserMessage === "ai" && (
          <div className="fact-checker-compliance-container">
            <div
              className="fact-checker-container"
              onClick={() => onFactCheckerClick()}
            >
              <p className="fact-checker-text">Fact Checker</p>
            </div>
            <div
              className="review-for-compliance-container"
              onClick={() => onComplianceClick()}
            >
              <p className="review-for-compliance-text">
                Review for Compliance
              </p>
            </div>

            {/* Generate Chart */}
            <div className="review-for-compliance-container" onClick={() => sendToChart()}>
              <p className="review-for-compliance-text">Generate Chart</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/*

<p className="compliance-text">Review for Compliance</p>

components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <div>
                  <div
                    className={`copy-markdown-icon`}
                    onClick={() => getMarkdownText(children)}
                  >
                    {showCopiedText && copyText === children[0] ? (
                      <p className="copied-text">copied</p>
                    ) : (
                      <IconContext.Provider
                        value={{
                          size: "20px",
                          color: "#e8e8e8",
                        }}
                      >
                        <div>
                          <BiCopy />
                        </div>
                      </IconContext.Provider>
                    )}
                  </div>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    language={match[1]}
                    style={lucario}
                    customStyle={{ fontSize: "14px" }}
                    PreTag="div"
                    {...props}
                  />
                </div>
              ) : (
                <p className={className} {...props}>
                  {children}
                </p>
              );
            },
          }}
*/

/*
{showCopiedMessage && <p className={`medium-font-${theme}`}>copied</p>}
                    {(!showCopiedMessage && aiOrUserMessage === "ai") &&
                        <IconContext.Provider
                            value={{ className: `copy-text-icon-${theme}` }}>
                            <div>
                                <BiCopy />
                            </div>
                        </IconContext.Provider>
                    }
                    */

export default SuperpoweredChatThread;
